import React, { useState, useCallback, useEffect } from 'react';

// context
import DashboardContext from './Dashboard.context';

// interface
import { CourseList } from 'interfaces/CourseList.types';
import { QuizList } from 'interfaces/QuizList.types';

// services
import { fetchTopCourses } from 'services/CourseService';
import { fetchRelatedCourses } from 'services/CourseService';
import { fetchUserQuizzes } from 'services/QuizService';
import { fetchMyStats } from 'services/UserService';

const DASHBOARD_RESULTS_COUNT = 1000;
const TOP_COURSES_COUNT = 4;

const DashboardState: React.FC<{ children: React.ReactNode }> = (props: { children: React.ReactNode }) => {
  const [topCourses, setTopCourses] = useState<CourseList[]>([]);
  const [pendingCourses, setPendingCourses] = useState<CourseList[]>([]);
  const [pendingQuizzes, setPendingQuizzes] = useState<QuizList[]>([]);
  const [completedCourses, setCompletedCourses] = useState<CourseList[]>([]);
  const [completedQuizzes, setCompletedQuizzes] = useState<QuizList[]>([]);
  const [completedCoursesCount, setCompletedCoursesCount] = useState<number>(0);
  const [incompleteCoursesCount, setIncompleteCoursesCount] = useState<number>(0);
  const [completedQuizzesCount, setCompletedQuizzesCount] = useState<number>(0);
  const [incompleteQuizzesCount, setIncompleteQuizzesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadDashboard = useCallback(async () => {

    setIsLoading(true);
    const { courses: top, error: topError } = await fetchTopCourses(null, TOP_COURSES_COUNT, 'registrations');
    if (!topError) {
      setTopCourses(top);
    }

    const { courses: completed, error: completedError } = await fetchRelatedCourses('completed=true', DASHBOARD_RESULTS_COUNT); // use user_course_registration.updated_at for this
    if (!completedError) {
      setCompletedCourses(completed);
    }

    const { courses: pending, error: pendingError } = await fetchRelatedCourses('progress=true', DASHBOARD_RESULTS_COUNT); // use completed_modules and user_course_registration.updated_at for this as well
    if (!pendingError) {
      setPendingCourses(pending);
    }

    const { quizzes: completedQuizzes, error: completedQuizzesError } = await fetchUserQuizzes(DASHBOARD_RESULTS_COUNT, null, true);
    if (!completedQuizzesError) {
      setCompletedQuizzes(completedQuizzes);
    }

    const { quizzes: incompleteQuizzes, error: incompleteQuizzesError } = await fetchUserQuizzes(DASHBOARD_RESULTS_COUNT, null, false);
    if (!incompleteQuizzesError) {
      setPendingQuizzes(incompleteQuizzes);
    }

    const { stats, error: statsError } = await fetchMyStats();
    if (!statsError) {
      setCompletedCoursesCount(stats.completedCourses);
      setIncompleteCoursesCount(stats.incompleteCourses);
      setCompletedQuizzesCount(stats.completedQuizzes);
      setIncompleteQuizzesCount(stats.incompleteQuizzes);
    }

    setIsLoading(false);

  }, []);

  useEffect(() => {
    loadDashboard();
  }, [loadDashboard]);

  const wrap = {
    isLoading,
    topCourses,
    pendingCourses,
    pendingQuizzes,
    completedCourses,
    completedQuizzes,
    completedCoursesCount,
    incompleteCoursesCount,
    completedQuizzesCount,
    incompleteQuizzesCount,
    setTopCourses,
    setCompletedCourses,
    setPendingCourses,
    setPendingQuizzes,
    setCompletedQuizzes,
    setIsLoading,
    setCompletedCoursesCount,
    setIncompleteCoursesCount,
    setCompletedQuizzesCount,
    setIncompleteQuizzesCount,
    loadDashboard
  };

  return (
    <DashboardContext.Provider value={wrap}>
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
