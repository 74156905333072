import React, { useState, useEffect, useCallback, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import History from "utils/History";

// Components
import CertificationsHeader from "components/CertificationsHeader/CertificationsHeader";
import Select from "components/Form/Select/Select";
// import Slider from "pages/Home/components/Slider/Slider";
import Certificate from "components/Certificate/Certificate";
import Listing from "../Certifications/components/Listing/Listing";

import GlobalContext from "contexts/Global.context";

import { QuizList } from "interfaces/QuizList.types";

import { fetchTopQuizzes } from "services/QuizService";

// Constants
import {
  CERTIFICATIONS_PAGE_SUBHEADING,
  CERTIFICATIONS_PAGE_HEADING,
  INDUSTRIES,
  CONTENT_TYPE_OPTIONS,
} from "constants/Constants";

// Text
import COPY_OVERRIDES from "config/CopyOverrides";

//SCSS;
import styles from "./Certifications.module.scss";

const AVAILABLE_QUIZZES = 100;
const ORDER_BY = "registrations";

const Certifications = () => {
  const [certifications, setCertifications] = useState<QuizList[]>([]);
  const { categories, isLoggedIn, variantDetails } = useContext(GlobalContext);
  const CertificateImg = `${variantDetails?.assets_url}/quiz_img.png`;
  const categoryOptions = isLoggedIn
    ? [{ label: "Recommended For Me", value: "forMe" }, ...categories]
    : categories;
  const { search, pathname } = useLocation();
  const { category: queryCategory, content_type: queryContentType } = queryString.parse(search);
  const [category, setCategory] = useState<string>(
    queryCategory ? decodeURIComponent(queryCategory.toString()) : ""
  );
  const [content_type, setContentType] = useState<string>(
    queryContentType ? decodeURIComponent(queryContentType.toString()) : ""
  );
  const [industry, setIndustry] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const updateQueryString = useCallback(() => {
    const query = [];
    if (category) {
      query.push(`category=${category}`);
    }
    if (content_type) {
      query.push(`content_type=${content_type}`);
    }
    if (query.length) {
      History.push(`${pathname}?${query.join("&")}`);
      return;
    }
    History.push(pathname);
  }, [category, pathname, content_type]);

  const getCertifications = useCallback(async () => {
    setLoading(true);
    updateQueryString();
    const { quizzes, total, error } = await fetchTopQuizzes(
      AVAILABLE_QUIZZES,
      ORDER_BY,
      category,
      { industry, content_type }
    );
    if (!error) {
      setCertifications(quizzes);
      setTotalCount(total);
    }
    setLoading(false);
  }, [category, industry, content_type, updateQueryString]);

  useEffect(() => {
    setCertifications([]);
    setTotalCount(0);
    getCertifications();
  }, [getCertifications, category, industry, content_type]);

  return (
    <>
      <Helmet>
        <title>{variantDetails?.site_title} | Credentials</title>
      </Helmet>
      <CertificationsHeader
        header={CERTIFICATIONS_PAGE_HEADING}
        image={CertificateImg}
        pageContent={
          COPY_OVERRIDES?.certifications?.subheader ||
          CERTIFICATIONS_PAGE_SUBHEADING
        }
      />
      <div className={styles.certificationsWapper}>
        <Container>
          <Row>
            <Col>
              <div className={styles.sectionHeading}>
                Available Credentials
              </div>
            </Col>
          </Row>

          <div className={styles.tabsParent}>
            <div className={styles.filterSection}>
              <Row className="align-items-center">
                <Col sm="auto">
                  <div className={styles.filterLabel}>Filter By</div>
                </Col>
                <Col sm="auto" className={styles.categoryFilter}>
                  <Select
                    id="category"
                    name="category"
                    onChange={(e) => setCategory(e.target.value)}
                    selectItems={categoryOptions}
                    value={category}
                    placeholder="Select Category"
                  />
                </Col>
                <Col sm="auto">
                  <Select
                    id="content_type"
                    name="content_type"
                    onChange={(e) => setContentType(e.target.value)}
                    selectItems={CONTENT_TYPE_OPTIONS}
                    value={content_type}
                    placeholder="Select Content Type"
                  />
                </Col>
                {!variantDetails?.site_short_title.includes(
                  "BeyondPhysician"
                ) && (
                  <Col sm="auto">
                    <Select
                      id="industry"
                      name="industry"
                      onChange={(e) => setIndustry(e.target.value)}
                      selectItems={INDUSTRIES}
                      value={industry}
                      placeholder="Select Industry"
                    />
                  </Col>
                )}
              </Row>
            </div>
            <div className={styles.tabContent}>
              <div className="tab-item-wrapper">
                <Row className={styles.tabRow}>
                  {!loading &&
                    !!certifications.length &&
                    certifications.map((certification, key) => {
                      return (
                        <Col key={key} xl="3" lg="3" md="6" xs="12">
                          <Certificate
                            id={certification.id}
                            image={certification.banner}
                            category={certification.category}
                            title={certification.title}
                            description={certification.description}
                            registrations={certification.registrations}
                          />
                        </Col>
                      );
                    })}
                  {loading && (
                    <div className="loader">
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </Row>
              </div>
            </div>
            {!!totalCount && (
              <span className={styles.resultsCount}>{totalCount} Results</span>
            )}
          </div>
        </Container>
      </div>
      <div className={styles.listingOuter}>
        <Listing />
      </div>
      {/* <Slider /> */}
    </>
  );
};

export default React.memo(Certifications);
