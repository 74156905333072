import { API_ENDPOINTS, API_METHODS, DEFAULT_API_HEADER } from '../constants/Constants';

export const fetchTopQuizzes = async (limit?: number, orderBy?: string, category?: string, others?: any) => {
  try {
    let qs = `?orderBy=${orderBy || 'registrations'}`;
    if (others) {
      if (others.industry) {
        qs += `&industry=${others.industry}`;
      }
      if (others.cost) {
        qs += `&cost=${others.cost}`;
      }
      if (others.content_type) {
        qs += `&content_type=${others.content_type}`;
      }
      if (others.keyword) {
        qs += `&title=${others.keyword}`;
      }
    }
    if (category) {
      qs += `&category=${category}`;
    }
    if (limit) {
      qs += `&limit=${limit}`;
    }
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_QUIZZES}${qs}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { quizzes, total } = await response.json();
      return { quizzes, total };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchUserQuizzes = async (limit?: number, orderBy?: string, isCompleted?: boolean) => {
  try {
    let qs = `?completed=${isCompleted}&orderBy=${orderBy || 'registrations'}`;
    if (limit) {
      qs += `&limit=${limit}`;
    }
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_QUIZZES}${qs}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const quizzes = await response.json();
      return { quizzes };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchQuizDetails = async (id: string, retake: boolean = false) => {
  try {
    let url = `${API_ENDPOINTS.FETCH_TOP_QUIZZES}/${id}/start`;
    if (retake) {
      url += '?retake=true'; 
    }
    const response = await fetch(url, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { quiz, result, registration, answers, completed, passed }  = await response.json();
      return { quiz, result, registration, answers, completed, passed };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchQuizSummary = async (id: string) => {
  try {
    let url = `${API_ENDPOINTS.FETCH_TOP_QUIZZES}/${id}/summary`;
    const response = await fetch(url, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { quiz, relatedCourse } =
        await response.json();
      return { quiz, relatedCourse };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const submitAnswer = async (id: string, answer: any) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_QUIZZES}/${id}/proceed`, {
      method: API_METHODS.POST,
      body: JSON.stringify(answer),
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { question, result, registration, answers } = await response.json();
      return { question, result, registration, answers };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const downloadCert = async (quizId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_QUIZZES}/${quizId}/certificate`, {
      method: API_METHODS.GET,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const data = await response.json();
      return { data };
    }
  } catch (err) {
    return { error: err.message };
  }
};